import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { updateUserPassword } from 'workspace/api/users.service';
import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import Password from 'common/components/form/Password';
import { useAuth } from 'workspace/providers/auth/AuthContext';

const PasswordResetModal = ({ showModal, onClose, userId }) => {
  const { logout } = useAuth();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { showLoader, hideLoader } = useLoader();

  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

  const handleSubmit = async () => {
    setPasswordError('');
    setError('');

    // Form Validation
    if (!password) {
      setPasswordError('Password is required');
      return;
    } else if (!passwordPattern.test(password)) {
      setPasswordError(
        'Your password must be at least 8 characters long and include a mix of uppercase and lowercase letters, numbers, and special characters.'
      );
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      showLoader();
      await updateUserPassword(userId, password);
      toastify.showSuccessToast('Your password has been updated successfully.');
      onClose();
      setTimeout(() => {
        logout();
      }, 4000);
    } catch (apiError) {
      toastify.showErrorToast(apiError?.message);
    } finally {
      hideLoader();
    }
  };

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="profile.resetPassword" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formPassword">
            <Form.Label>
              <FormattedMessage id="profile.newPassword" />
            </Form.Label>
            <Password placeholder="Enter new password" value={password} onChange={e => setPassword(e.target.value)} />
            {passwordError && <div className="text-danger mt-2">{passwordError}</div>}
          </Form.Group>
          <div className="mb-3" />
          <Form.Group controlId="formConfirmPassword" className="mb-2">
            <Form.Label>
              <FormattedMessage id="profile.confirmPassword" />
            </Form.Label>
            <Password
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </Form.Group>

          {error && (
            <div className="text-danger mt-2">
              <FormattedMessage id="profile.passwordMismatch" defaultMessage={error} />
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          <FormattedMessage id="profile.cancel" />
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          <FormattedMessage id="profile.reset" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PasswordResetModal;
