import { v4 as uuidv4 } from 'uuid';

class Test {
  constructor(folderGuid, testId, title, tabTitle, course, questions, criterias, metadata) {
    this.id = uuidv4();
    this.testId = testId;
    this.folderGuid = folderGuid || 'null';
    this.title = title;
    this.tabTitle = tabTitle;
    this.course = course;
    this.questions = questions || [];
    this.criterias = criterias;
    this.metadata = metadata || {};
  }
}

export default Test;
