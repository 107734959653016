import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { toastify } from 'common/components/Toastify';
import { useAppContext } from 'test-builder/context/AppContext';
import randomizeTest from '../../workspace/data/randomizetest.json';

const MAX_ALLOWED_TEST_TABS = process.env.REACT_APP_MAX_ALLOWED_TEST_TABS_COUNT;

const VERSION_OPTIONS = Array.from({ length: 10 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1} Version${i > 0 ? 's' : ''}`,
}));

const RandomizeTestVersionModal = ({ handleClose }) => {
  const intl = useIntl();
  const { tests, dispatchEvent } = useAppContext();
  const [selectedVersions, setSelectedVersions] = useState(1);
  const [isViewVersionsChecked, setIsViewVersionsChecked] = useState(false);

  const handleVersionChange = event => {
    setSelectedVersions(Number(event.target.value));
  };

  const handleViewVersionsChange = event => {
    setIsViewVersionsChecked(event.target.checked);
  };

  const handleAddNewTestTabs = () => {
    if (!isViewVersionsChecked) {
      //API call
    } else {
      const testsToOpen = randomizeTest.slice(0, selectedVersions);

      if (tests.length + selectedVersions > MAX_ALLOWED_TEST_TABS) {
        const msg = intl.formatMessage({ id: 'warning.moretabs' }, { count: MAX_ALLOWED_TEST_TABS });
        toastify.showWarningToast(msg);
        return;
      }

      testsToOpen.forEach(test => {
        dispatchEvent('HANDLE_VIEW_OR_EDIT_TEST_CLICK', test);
      });
      handleClose();
    }
  };

  return (
    <CustomModal show size="sm">
      <CustomModal.Header onClose={handleClose}>
        <FormattedMessage id="testcreate.randomize.modal.title" defaultMessage="Randomize Versions of Test" />
      </CustomModal.Header>
      <CustomModal.Body>
        <div>
          <Form.Check type="checkbox" id="questions" label="Question" />
          <Form.Check type="checkbox" id="answers" label="Answers" />
        </div>
        <hr />
        <div className="d-flex">
          <Form.Group controlId="formPublisher" className="w-50 mb-2">
            <Form.Select name="versions" size="sm" onChange={handleVersionChange} value={selectedVersions}>
              {VERSION_OPTIONS.map(version => (
                <option key={version.value} value={version.value}>
                  {version.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Check
            className="ml-5 mt-1"
            type="checkbox"
            id="viewversions"
            label="View Versions"
            checked={isViewVersionsChecked}
            onChange={handleViewVersionsChange}
          />
        </div>
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button variant="secondary" size="sm" onClick={handleClose}>
          <FormattedMessage id="message.create" defaultMessage="Cancel" />
        </Button>
        <Button variant="primary" size="sm" onClick={handleAddNewTestTabs}>
          <FormattedMessage id="message.save" defaultMessage="Create" />
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

export default RandomizeTestVersionModal;
