import axios from './axios';

/**
 * Fetches the folders within a specific folder of a book.
 *
 * @param {string} bookId - The ID of the book.
 * @param {string} folderId - The ID of the folder to fetch subfolders from.
 * @returns {Promise} - A promise that resolves to the response of the GET request.
 */
export async function getFolders(bookId, folderId) {
  return axios.get(`/ws/books/${bookId}/folders/${folderId}/folders`);
}

/**
 * Retrieves the root folder ID of a specific book.
 *
 * @param {string} bookId - The ID of the book.
 * @returns {Promise} - A promise that resolves to the response of the GET request.
 */
export async function getRootFolderId(bookId) {
  return axios.get(`/ws/books/${bookId}/rootFolder`);
}

/**
 * Creates a new folder in a specific book.
 *
 * @param {string} bookId - The ID of the book.
 * @param {Object} payload - The data for the new folder.
 * @returns {Promise} - A promise that resolves to the response of the POST request.
 */
export async function createFolder(bookId, payload) {
  return axios.post(`/ws/books/${bookId}/folders`, payload);
}

/**
 * Updates an existing folder in a specific book.
 *
 * @param {string} bookId - The ID of the book.
 * @param {Object} payload - The updated data for the folder, including its GUID.
 * @returns {Promise} - A promise that resolves to the response of the PUT request.
 */
export async function updateFolder(bookId, payload) {
  return axios.put(`/ws/books/${bookId}/folders/${payload.guid}`, payload);
}

/**
 * Deletes a folder from a specific book.
 *
 * @param {string} bookId - The ID of the book.
 * @param {string} folderId - The ID of the folder to delete.
 * @returns {Promise} - A promise that resolves to the response of the DELETE request.
 */
export async function deleteFolder(bookId, folderId) {
  return axios.delete(`/ws/books/${bookId}/folders/${folderId}`);
}

/**
 * Fetches the questions within a specific folder of a book.
 *
 * @param {string} bookId - The ID of the book.
 * @param {string} folderId - The ID of the folder to fetch questions from.
 * @returns {Promise} - A promise that resolves to the response of the GET request.
 */
export async function getQuestions(bookId, folderId) {
  return axios.get(`/ws/books/${bookId}/folders/${folderId}/questions`);
}

/**
 * Saves questions to a specific folder in a book.
 *
 * @param {string} bookId - The ID of the book.
 * @param {string} folderId - The ID of the folder to save questions to.
 * @param {Object} payload - The data for the questions to be saved.
 * @returns {Promise} - A promise that resolves to the response of the POST request.
 */
export async function saveQuestions(bookId, folderId, payload) {
  return axios.post(`/ws/books/${bookId}/folders/${folderId}/questions`, payload);
}

/**
 * Updates questions in a specific folder of a book.
 *
 * @param {string} bookId - The ID of the book.
 * @param {string} folderId - The ID of the folder containing the questions.
 * @param {Object} payload - The updated data for the questions.
 * @returns {Promise} - A promise that resolves to the response of the PUT request.
 */
export async function updateQuestion(bookId, folderId, payload) {
  return axios.put(`/ws/books/${bookId}/folders/${folderId}/questions`, payload);
}

/**
 * Deletes a question from a specific folder in the book.
 *
 * @param {string} bookId - The ID of the book.
 * @param {string} folderId - The ID of the folder containing the question.
 * @param {string} questionGuid - The GUID of the question to be deleted.
 * @returns {Promise} - A promise that resolves to the response of the DELETE request.
 */
export async function deleteQuestion(bookId, folderId, questionGuid) {
  return axios.delete(`/ws/books/${bookId}/folders/${folderId}/questions/${questionGuid}`);
}

/**
 * Swaps a question between two folders in a specified book.
 *
 * @param {string} bookId - The ID of the book containing the folders.
 * @param {string} sfolderId - The ID of the source folder from which the question will be moved.
 * @param {string} dfolderId - The ID of the destination folder to which the question will be moved.
 * @param {string} questionId - The ID of the question to be swapped.
 * @returns {Promise} - A promise that resolves to the response of the HTTP PUT request.
 */
export const swapQuestionBetweenFolders = (bookId, sfolderId, dfolderId, questionId) => {
  return axios.put(`/ws/books/${bookId}/folders/${sfolderId}/folders/${dfolderId}/questions/${questionId}`);
};

/**
 * Imports questions from a file into a specific folder of a book.
 *
 * @param {string} bookId - The ID of the book.
 * @param {string} folderId - The ID of the folder to import questions into.
 * @param {File} file - The file containing questions to import.
 * @returns {Promise} - A promise that resolves to the response of the POST request.
 */
export async function importQuestions(bookId, folderId, file) {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`/ws/books/${bookId}/folders/${folderId}/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
