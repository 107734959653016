import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { addWorkspace } from 'workspace/api/workspace.service';
import { updateWorkspace } from 'workspace/api/workspace.service';
import { useLoader } from 'common/providers/LoaderProvider';
import { useAutoFocus } from 'common/hooks/useAutoFocus';
import { toastify } from 'common/components/Toastify';

const AddWorkspaceModal = ({ editWorkspace, handleClose, reload }) => {
  const [formData, setFormData] = useState({
    name: editWorkspace?.name || '',
    description: editWorkspace?.description || '',
  });
  const { showLoader, hideLoader } = useLoader();
  const intl = useIntl();
  const autoFocusRef = useAutoFocus();

  const [errors, setErrors] = useState({
    name: false,
  });

  const [submitted, setSubmitted] = useState(false);

  const validate = (name, value) => {
    if (name === 'name' && !value.trim()) {
      return 'ws.error.nameRequired';
    }
    return '';
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const validationError = validate(name, value);
    setErrors(prev => ({ ...prev, [name]: validationError }));
  };

  const getValidationErrors = formData => {
    const validationErrors = {};

    Object.entries(formData).forEach(([key, value]) => {
      const error = validate(key, value);
      if (error) {
        validationErrors[key] = error;
      }
    });

    return validationErrors;
  };

  const handleSaveWorkspace = async () => {
    setSubmitted(true);

    const validationErrors = getValidationErrors(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const isUpdating = Boolean(editWorkspace);
    const saveAPIMethod = isUpdating ? updateWorkspace : addWorkspace;
    const successMessage = isUpdating ? 'ws.updateWorkspace' : 'ws.addWorkspace';
    const errorMessage = isUpdating ? 'ws.updateWorkspaceError' : 'ws.addWorkspaceError';

    try {
      showLoader();

      // calls save api method
      await saveAPIMethod(formData, editWorkspace?.id);

      toastify.showSuccessToast(intl.formatMessage({ id: successMessage }));
      handleClose();
      reload();
    } catch (error) {
      if (error.status === 409) {
        toastify.showWarningToast(error.message, { className: 'toast-width-large' });
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: errorMessage }));
      }
    } finally {
      hideLoader();
    }
  };

  return (
    <CustomModal size="lg" show centered={false}>
      <CustomModal.Header onClose={handleClose}>
        <FormattedMessage
          id={editWorkspace ? 'ws.editworkspace' : 'ws.workspacecreation'}
          defaultMessage={editWorkspace ? 'Workspace Creation' : 'Edit Workspace'}
        />
      </CustomModal.Header>
      <CustomModal.Body>
        <Form>
          <Form.Group controlId="formWorkspaceTitle" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.workspacetitle" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Form.Control
              ref={autoFocusRef}
              type="text"
              placeholder="Enter Workspace Title"
              name="name"
              value={formData.name}
              onChange={handleChange}
              maxLength={100}
              className="form-control-workspace"
            />
            {submitted && errors.name && (
              <div className="form-error-message">
                <FormattedMessage id={errors.name} />
              </div>
            )}
          </Form.Group>
          <Form.Group controlId="formWorkspaceDescription" className="mb-3">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="ws.workspaceDescription" />
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter Workspace Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button variant="secondary" size="sm" onClick={handleClose}>
          <FormattedMessage id="message.cancel" defaultMessage="Cancel" />
        </Button>
        <Button variant="primary" size="sm" onClick={handleSaveWorkspace}>
          <FormattedMessage id="message.save" defaultMessage="Save" />
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

export default AddWorkspaceModal;
